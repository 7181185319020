.hobbies {
  position: relative;
  height: 100%;
  padding-left: 70px;
  column-gap: 10%;
}
.hobbies h1 {
  margin-bottom: 5%;
}
.portada__container {
  overflow: visible !important;
  z-index: -1;
}

.hobby-image {
  max-width: 100px;
}
.separator {
  height: 50px;
}
/* Fila de cada hobby */
.hobby-row {
  display: grid;
  grid-template-columns: 10% 1fr 15%;
  align-items: center;
  gap: 15px;
  background: rgba(255, 255, 255, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: background 0.3s ease;
}

.hobby-row:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Imagen de cada hobby */
.hobby-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
}

/* Texto de cada hobby */
.hobby-text h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #fff;
}

.hobby-text p {
  margin: 5px 0 0;
  font-size: 1rem;
  color: #ddd;
}

.hobby-button-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.hobby-button-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Cambia según el fondo de tu imagen */
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  pointer-events: none; /* Evita que el texto bloquee el clic */
}

.hobby-button {
  border: none;
  color: white;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Fondo oscuro */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.hobby-modal {
  background: transparent;
  padding: 20px;

  border-radius: 10px;
  max-width: 100%;
  width: 70%;
  padding-top: 5%;
  height: 80%; /* Ajustamos la altura del modal para que ocupe el 80% de la pantalla */
  display: flex;
  flex-direction: column; /* Esto asegura que los elementos dentro del modal estén en una columna */
  justify-content: flex-start; /* Alinea el contenido al inicio */
  background-image: url("../assets/page.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center; /* Alinea la imagen desde la parte superior */
  overflow-y: auto; /* Permite el desplazamiento vertical */
  padding-left: 10%;
  padding-right: 10%;
  align-items: center;
  text-align: center;
  animation-duration: 0.4s; /* Duración de la animación */
  animation-timing-function: ease forwards;
}
.slideUp {
  animation-name: slideUp; /* Cuando aparece */
}
.slideDown {
  animation-name: slideDown; /* Cuando aparece */
}
.hobby-modal::-webkit-scrollbar {
  visibility: hidden;
}

.hobby-modal img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

/* Si el contenido es demasiado largo, la caja se desplazará hacia abajo */

/* Animación */
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideDown {
  from {
    transform: translateY(0); /* Comienza desde su posición actual */
  }
  to {
    transform: translateY(100%); /* Se mueve hacia abajo */
  }
}
@media screen and (max-width: 600px) {
  .hobby-text p {
    display: none;
  }
  .hobby-row {
    grid-template-columns: 15% 1fr 30%;
  }
  .hobby-row h2 {
    font-size: 12px;
  }
  .hobby-button-text {
    font-size: 12px;
  }
  .hobbies {
    padding-left: 0px;
  }
  .hobby-modal {
    width: 100%;
  }
  .third__font {
    width: 100%;
    font-size: 12px;
    word-wrap: break-word !important; /* Esto permite que las palabras se quiebren si es necesario */
    overflow-wrap: break-word;
  }
  .hobby-modal::-webkit-scrollbar {
    visibility: visible;
  }
}
