:root {
  --color-mid-yellow: #faf7be;
  --color-purple: rebeccapurple;
  --color-mid-red: #d32f2f;
}

.Project__panel {
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 10%;
}
.portada__container {
  overflow: visible !important;
}
.panel__infographics {
  width: 100%;
  min-height: 100vh;
  z-index: 9999;
  row-gap: 30px;
  padding-left: 70px;
}
.infographics__projects {
  grid-template-rows: 100px 1fr;
}
.infographics__projects h2 {
  align-self: center;
  justify-self: center;
}
.blur-background {
  background: var(--color-mid-yellow); /* Blanco con transparencia */
  backdrop-filter: blur(15px); /* Suaviza el fondo */
  -webkit-backdrop-filter: blur(15px); /* Compatibilidad con Safari */
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.infographics__projects img {
  max-width: 600px;
  max-height: 300px;
  align-self: center;
  height: 300px;
}

.fawtrucks_display {
  justify-self: center;
  width: 100%;
  height: 100%;
  row-gap: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#fawtrucks_firstimg {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.note {
  cursor: grab;
  bottom: 5%;
  right: 15%;
  z-index: 9999;
  max-width: 250px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
  font-weight: lighter;
  letter-spacing: 1px;
  position: absolute;
  min-height: 200px;
  max-height: 200px;
  min-width: 250px;
  z-index: 9999;
  transform: translateX(-50%) rotate(-10deg);
  font-weight: 100;
}

.note__title {
  font-weight: 500;
}
.note__description {
  font-weight: 300; /* Peso más liviano */
}
.projects__header {
  width: 500px;
  height: 100%;
  align-self: center;
  justify-self: center;
}

.header__company {
  border: 1px solid white;
  padding: 5px;
  column-gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 35px;
  min-width: 200px;
}
.header__company p {
  margin: 0;
}
.PortadaBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px !important;
  z-index: -1; /* Envía la portada al fondo */
}
.Cincuentenario {
  color: var(--color-purple) !important;
}
.Faw {
  color: var(--color-mid-red) !important;
}

@media (max-width: 768px) {
  .note__additional {
    display: none; /*  #comentario prueba*/
  }
  .fawtrucks_display {
    padding-left: 10px;
    padding-right: 10px;
  }
  .projects__header {
    flex-direction: column;
  }
  .infographics__projects {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .panel__infographics {
    padding-bottom: 10%;
  }
  .note__description {
    font-size: 14px;
  }
  .note {
    display: none;
  }
}
@media (max-width: 430px) {
  .panel__infographics h1 {
    font-size: 24px;
  }
  .note {
    width: 100px;
    min-width: 180px;
  }
}
@media screen and (max-width: 500px) {
  .panel__infographics {
    padding: 0;
  }
}
