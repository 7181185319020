@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dongle&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dongle&family=Press+Start+2P&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --color-black: #000000;
  --color-white: #ffffff;
}
/* - - - - - - - - - - - - - - - - -*/
/* FLEX BOXES 
/* - - - - - - - - - - - - - - - - -*/
.flex__row {
  display: flex;
  flex-direction: row;
}
.flex__column {
  display: flex;
  flex-direction: column;
}
.flex__center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex__between {
  display: flex;
  justify-content: space-between;
}
.flex__around {
  display: flex;
  justify-content: space-around;
}
.grid__center {
  display: grid;
  align-items: center;
  justify-content: center;
}
.grid__align__center {
  display: grid;
  align-items: center;
}
.grid__justify__center {
  display: grid;
  justify-items: center;
}
.align__center {
  align-items: center;
}
.justify__between {
  justify-content: space-between;
}
.justify__around {
  justify-content: space-around;
}
.justify__center {
  justify-content: center;
}
.all__centered {
  align-items: center;
  justify-content: center;
}
.self__center {
  align-self: center;
  justify-self: center;
}
/* - - - - - - - - - - - - - - - - -*/
/* VISIBILIDAD
/* - - - - - - - - - - - - - - - - -*/
.hidden {
  display: none;
}
.visible {
  display: block;
}
.overflow__hidden {
  overflow: hidden;
}
.position__relative {
  position: relative;
}
/* - - - - - - - - - - - - - - - - -*/
/* FONDO Y COLORES
/* - - - - - - - - - - - - - - - - -*/
.background__black {
  background-color: var(--color-black);
}
.color__black {
  color: var(--color-black);
}
.color__white {
  color: var(--color-white);
}
/* - - - - - - - - - - - - - - - - -*/
/* FUENTES
/* - - - - - - - - - - - - - - - - -*/
.first__font {
  font-family: "Press Start 2P", cursive;
}
.second__font {
  font-family: "Unica One", sans-serif;
}
.third__font {
  font-family: "Roboto Mono", monospace;
}
/* - - - - - - - - - - - - - - - - -*/
/* ALIGNS
/* - - - - - - - - - - - - - - - - -*/
.text__center {
  text-align: center;
}
.text__left {
  text-align: left;
}
/* - - - - - - - - - - - - - - - - -*/
/* RADIUS
/* - - - - - - - - - - - - - - - - -*/
.radius__10px {
  border-radius: 10px;
}
.radius__100px {
  border-radius: 100px;
}
/* - - - - - - - - - - - - - - - - -*/
/* SIZE
/* - - - - - - - - - - - - - - - - -*/
.size__24px {
  font-size: 24px;
}
.size__12px {
  font-size: 12px;
}
.size__16px {
  font-size: 16px;
}
.size__32px {
  font-size: 32px;
}
/* - - - - - - - - - - - - - - - - -*/
/* WIDTH
/* - - - - - - - - - - - - - - - - -*/
.width__full {
  width: 100vw;
  height: 100vh;
}
/* - - - - - - - - - - - - - - - - -*/
/* DEFAULT PADDING
/* - - - - - - - - - - - - - - - - -*/
.padding_default {
  padding-left: 70px;
}
.text_decoration__none {
  text-decoration: none;
}
