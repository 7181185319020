@import url("https://fonts.googleapis.com/css2?family=Unica+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&family=Unica+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Jaldi&display=swap");

h1 {
  opacity: 1 !important;
}
.portada__background {
  width: 100%;
  height: 100%;
}
.portada__container {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}
.portada__container_background {
  height: 100%;
  width: 100%;
  background-color: black;
  position: absolute;
  z-index: -999999;
  overflow: hidden;
}
.portada__data {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 100px;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.portada_bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.portada_bottom p {
  margin: 0 30px;
  text-align: center !important;
  color: #fff;
}
.portada__middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0;
  padding-left: 70px;
}
.portada__middle h1 {
  padding: 5px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  font-size: 42px;
}
.portada__middle h1,
.portada__middle h2 {
  font-family: "Press Start 2P", cursive;
  font-weight: 100;
  font-style: normal;
}
.portada__middle h2 {
  font-size: 24px;
  text-transform: uppercase;
}
.scroll-downs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mousey {
  width: 3px;
  padding: 10px 12px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
.reunion-button {
  position: fixed;
  bottom: 4%;
  right: 2%;
  z-index: 99999;
  border: none;
}
.reunion-button .button {
  width: 200px;
  height: 40px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid white;
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6); /* Negro con opacidad */
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
}

.reunion-button .button,
.reunion-button .button__icon,
.reunion-button .button__text {
  transition: all 0.6s;
}

.reunion-button .button .button__text {
  transform: translateX(10px);
  color: white;
  font-weight: 600;
}

.reunion-button .button .button__icon {
  position: absolute;
  transform: translateX(150px);
  height: 100%;
  width: 40px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reunion-button .button img {
  width: 20px;
  padding: 0;
  margin: 0;
  align-self: center;
  fill: white;
}

.reunion-button .button:hover {
  background: white;
  transition: all 0.1s ease-out;
}

.reunion-button .button:hover .button__text {
  color: transparent;
}

.reunion-button .button:hover .button__icon {
  width: 150px;
  transform: translateX(10%);
}

.csv_downloader {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
  cursor: pointer;
}

.csv_downloader img {
  max-width: 100px;
  transition: opacity 0.3s ease; /* Para un cambio más suave */
}

/* Mostrar la imagen normal (csv) y ocultar la imagen de csvopen */
.csv-open-icon {
  display: none; /* Por defecto, ocultamos la imagen 'csvopen' */
}

/* Al hacer hover sobre el contenedor, ocultamos la imagen de csv y mostramos la de csvopen */
.csv_downloader:hover .csv-icon {
  display: none; /* Ocultamos la imagen 'csv' */
}

.csv_downloader:hover .csv-open-icon {
  display: inline; /* Mostramos la imagen 'csvopen' */
}

@media (max-width: 650px) {
  .portada__middle h1 {
    font-size: 32px;
  }
  .portada__middle h2 {
    font-size: 24px;
  }
  .portada_bottom {
    display: none;
  }
  .csv_downloader img {
    max-width: 100px;
    max-height: 50px;
  }
  .csv_downloader {
    top: 1%;
  }
}
@media (max-width: 430px) {
  .portada__middle h1 {
    font-size: 24px;
  }
  .portada__middle h2 {
    font-size: 16px;
  }
}
@media screen and (max-width: 500px) {
  .portada__middle {
    padding: 0;
  }
}
