.footer {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: center;
  font-size: 16px;
  color: white;
  overflow: hidden;
  position: relative;
}

/* Contenedor con imagen repetida horizontalmente */
.footer__pattern {
  width: 100%;
  height: 50%; /* Ajusta la altura según el diseño */
  background-image: url("../assets/footer.webp");
  background-repeat: repeat-x; /* Se repite solo en horizontal */
  background-size: auto 100%; /* Mantiene el tamaño original en altura */
  background-position: center;
}
.footer__rights {
  width: 100%;
}
.footer__rights p {
  white-space: nowrap;
  font-weight: 500;
  position: absolute;
  bottom: 0%; /* Ajusta según necesites */
  left: 50%; /* Lo posiciona en el centro */
  transform: translateX(-50%); /* Lo desplaza correctamente */
  color: white;
  text-align: center;
}
