/* - - - - - - - - - - - - - - - - -*/
/* GRID Y FLEX
/* - - - - - - - - - - - - - - - - -*/
.Estudios {
  display: grid;
}
.first__skills,
.skills__stats,
.stats__languaje {
  display: flex;
}
.skills__stats,
.stats__languaje {
  flex-direction: column;
}
.portada__container {
  overflow: visible !important;
}
/* - - - - - - - - - - - - - - - - -*/
/* ALIGN ITEMS
/* - - - - - - - - - - - - - - - - -*/
.skills__stats,
.first__skills,
.languaje__title {
  align-items: center;
}
.Estudios,
.skills__stats,
.studies,
.stats__languaje {
  justify-content: center;
}
.first__skills,
.stats__languaje {
  align-items: flex-start;
}
/* - - - - - - - - - - - - - - - - -*/
/* POSITION ABSOLUTE
/* - - - - - - - - - - - - - - - - -*/
.academics__save__img,
.skills__stats,
.chargin__skill {
  position: absolute;
}
.Estudios {
  padding-bottom: 5%;
  min-height: 100vh;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 70px;
  position: relative;
  overflow: hidden;
}

.estudios__first {
  min-width: 50%;
}
.academics__img {
  max-height: 60px;
}
.academics__save__img {
  width: 600px;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skills__stats {
  width: 500px;
}
.pixelart-to-css {
  box-shadow: 15px 5px 0 0 #ffffff, 20px 5px 0 0 #ffffff, 25px 5px 0 0 #ffffff,
    30px 5px 0 0 #ffffff, 35px 5px 0 0 #ffffff, 40px 5px 0 0 #ffffff,
    45px 5px 0 0 #ffffff, 50px 5px 0 0 #ffffff, 55px 5px 0 0 #ffffff,
    60px 5px 0 0 #ffffff, 65px 5px 0 0 #ffffff, 70px 5px 0 0 #ffffff,
    75px 5px 0 0 #ffffff, 80px 5px 0 0 #ffffff, 85px 5px 0 0 #ffffff,
    90px 5px 0 0 #ffffff, 95px 5px 0 0 #ffffff, 100px 5px 0 0 #ffffff,
    105px 5px 0 0 #ffffff, 110px 5px 0 0 #ffffff, 115px 5px 0 0 #ffffff,
    120px 5px 0 0 #ffffff, 125px 5px 0 0 #ffffff, 130px 5px 0 0 #ffffff,
    135px 5px 0 0 #ffffff, 140px 5px 0 0 #ffffff, 145px 5px 0 0 #ffffff,
    150px 5px 0 0 #ffffff, 155px 5px 0 0 #ffffff, 160px 5px 0 0 #ffffff,
    165px 5px 0 0 #ffffff, 170px 5px 0 0 #ffffff, 175px 5px 0 0 #ffffff,
    180px 5px 0 0 #ffffff, 185px 5px 0 0 #ffffff, 190px 5px 0 0 #ffffff,
    195px 5px 0 0 #ffffff, 200px 5px 0 0 #ffffff, 205px 5px 0 0 #ffffff,
    210px 5px 0 0 #ffffff, 215px 5px 0 0 #ffffff, 220px 5px 0 0 #ffffff,
    225px 5px 0 0 #ffffff, 230px 5px 0 0 #ffffff, 235px 5px 0 0 #ffffff,
    240px 5px 0 0 #ffffff, 245px 5px 0 0 #ffffff, 250px 5px 0 0 #ffffff,
    255px 5px 0 0 #ffffff, 260px 5px 0 0 #ffffff, 265px 5px 0 0 #ffffff,
    270px 5px 0 0 #ffffff, 275px 5px 0 0 #ffffff, 280px 5px 0 0 #ffffff,
    285px 5px 0 0 #ffffff, 290px 5px 0 0 #ffffff, 295px 5px 0 0 #ffffff,
    300px 5px 0 0 #ffffff, 305px 5px 0 0 #ffffff, 310px 5px 0 0 #ffffff,
    315px 5px 0 0 #ffffff, 320px 5px 0 0 #ffffff, 325px 5px 0 0 #ffffff,
    330px 5px 0 0 #ffffff, 335px 5px 0 0 #ffffff, 340px 5px 0 0 #ffffff,
    345px 5px 0 0 #ffffff, 350px 5px 0 0 #ffffff, 355px 5px 0 0 #ffffff,
    360px 5px 0 0 #ffffff, 365px 5px 0 0 #ffffff, 370px 5px 0 0 #ffffff,
    375px 5px 0 0 #ffffff, 380px 5px 0 0 #ffffff, 385px 5px 0 0 #ffffff,
    390px 5px 0 0 #ffffff, 395px 5px 0 0 #ffffff, 400px 5px 0 0 #ffffff,
    405px 5px 0 0 #ffffff, 410px 5px 0 0 #ffffff, 415px 5px 0 0 #ffffff,
    420px 5px 0 0 #ffffff, 425px 5px 0 0 #ffffff, 430px 5px 0 0 #ffffff,
    435px 5px 0 0 #ffffff, 440px 5px 0 0 #ffffff, 445px 5px 0 0 #ffffff,
    450px 5px 0 0 #ffffff, 455px 5px 0 0 #ffffff, 460px 5px 0 0 #ffffff,
    465px 5px 0 0 #ffffff, 470px 5px 0 0 #ffffff, 475px 5px 0 0 #ffffff,
    480px 5px 0 0 #ffffff, 485px 5px 0 0 #ffffff, 490px 5px 0 0 #ffffff,
    10px 10px 0 0 #ffffff, 495px 10px 0 0 #ffffff, 5px 15px 0 0 #ffffff,
    20px 15px 0 0 #ffffff, 25px 15px 0 0 #ffffff, 30px 15px 0 0 #ffffff,
    35px 15px 0 0 #ffffff, 40px 15px 0 0 #ffffff, 45px 15px 0 0 #ffffff,
    50px 15px 0 0 #ffffff, 55px 15px 0 0 #ffffff, 60px 15px 0 0 #ffffff,
    65px 15px 0 0 #ffffff, 70px 15px 0 0 #ffffff, 75px 15px 0 0 #ffffff,
    80px 15px 0 0 #ffffff, 85px 15px 0 0 #ffffff, 90px 15px 0 0 #ffffff,
    95px 15px 0 0 #ffffff, 100px 15px 0 0 #ffffff, 105px 15px 0 0 #ffffff,
    110px 15px 0 0 #ffffff, 115px 15px 0 0 #ffffff, 120px 15px 0 0 #ffffff,
    125px 15px 0 0 #ffffff, 130px 15px 0 0 #ffffff, 135px 15px 0 0 #ffffff,
    140px 15px 0 0 #ffffff, 145px 15px 0 0 #ffffff, 150px 15px 0 0 #ffffff,
    155px 15px 0 0 #ffffff, 160px 15px 0 0 #ffffff, 165px 15px 0 0 #ffffff,
    170px 15px 0 0 #ffffff, 175px 15px 0 0 #ffffff, 180px 15px 0 0 #ffffff,
    185px 15px 0 0 #ffffff, 190px 15px 0 0 #ffffff, 195px 15px 0 0 #ffffff,
    200px 15px 0 0 #ffffff, 205px 15px 0 0 #ffffff, 210px 15px 0 0 #ffffff,
    215px 15px 0 0 #ffffff, 220px 15px 0 0 #ffffff, 225px 15px 0 0 #ffffff,
    230px 15px 0 0 #ffffff, 235px 15px 0 0 #ffffff, 240px 15px 0 0 #ffffff,
    245px 15px 0 0 #ffffff, 250px 15px 0 0 #ffffff, 255px 15px 0 0 #ffffff,
    260px 15px 0 0 #ffffff, 265px 15px 0 0 #ffffff, 270px 15px 0 0 #ffffff,
    275px 15px 0 0 #ffffff, 280px 15px 0 0 #ffffff, 285px 15px 0 0 #ffffff,
    290px 15px 0 0 #ffffff, 295px 15px 0 0 #ffffff, 300px 15px 0 0 #ffffff,
    305px 15px 0 0 #ffffff, 310px 15px 0 0 #ffffff, 315px 15px 0 0 #ffffff,
    320px 15px 0 0 #ffffff, 325px 15px 0 0 #ffffff, 330px 15px 0 0 #ffffff,
    335px 15px 0 0 #ffffff, 340px 15px 0 0 #ffffff, 345px 15px 0 0 #ffffff,
    350px 15px 0 0 #ffffff, 355px 15px 0 0 #ffffff, 360px 15px 0 0 #ffffff,
    365px 15px 0 0 #ffffff, 370px 15px 0 0 #ffffff, 375px 15px 0 0 #ffffff,
    380px 15px 0 0 #ffffff, 385px 15px 0 0 #ffffff, 390px 15px 0 0 #ffffff,
    395px 15px 0 0 #ffffff, 400px 15px 0 0 #ffffff, 405px 15px 0 0 #ffffff,
    410px 15px 0 0 #ffffff, 415px 15px 0 0 #ffffff, 420px 15px 0 0 #ffffff,
    425px 15px 0 0 #ffffff, 430px 15px 0 0 #ffffff, 435px 15px 0 0 #ffffff,
    440px 15px 0 0 #ffffff, 445px 15px 0 0 #ffffff, 450px 15px 0 0 #ffffff,
    455px 15px 0 0 #ffffff, 460px 15px 0 0 #ffffff, 465px 15px 0 0 #ffffff,
    470px 15px 0 0 #ffffff, 475px 15px 0 0 #ffffff, 480px 15px 0 0 #ffffff,
    485px 15px 0 0 #ffffff, 500px 15px 0 0 #ffffff, 5px 20px 0 0 #ffffff,
    15px 20px 0 0 #ffffff, 490px 20px 0 0 #ffffff, 500px 20px 0 0 #ffffff,
    5px 25px 0 0 #ffffff, 15px 25px 0 0 #ffffff, 490px 25px 0 0 #ffffff,
    500px 25px 0 0 #ffffff, 5px 30px 0 0 #ffffff, 15px 30px 0 0 #ffffff,
    490px 30px 0 0 #ffffff, 500px 30px 0 0 #ffffff, 5px 35px 0 0 #ffffff,
    15px 35px 0 0 #ffffff, 490px 35px 0 0 #ffffff, 500px 35px 0 0 #ffffff,
    5px 40px 0 0 #ffffff, 15px 40px 0 0 #ffffff, 490px 40px 0 0 #ffffff,
    500px 40px 0 0 #ffffff, 5px 45px 0 0 #ffffff, 15px 45px 0 0 #ffffff,
    490px 45px 0 0 #ffffff, 500px 45px 0 0 #ffffff, 5px 50px 0 0 #ffffff,
    15px 50px 0 0 #ffffff, 490px 50px 0 0 #ffffff, 500px 50px 0 0 #ffffff,
    5px 55px 0 0 #ffffff, 15px 55px 0 0 #ffffff, 490px 55px 0 0 #ffffff,
    500px 55px 0 0 #ffffff, 5px 60px 0 0 #ffffff, 15px 60px 0 0 #ffffff,
    490px 60px 0 0 #ffffff, 500px 60px 0 0 #ffffff, 5px 65px 0 0 #ffffff,
    15px 65px 0 0 #ffffff, 490px 65px 0 0 #ffffff, 500px 65px 0 0 #ffffff,
    5px 70px 0 0 #ffffff, 15px 70px 0 0 #ffffff, 490px 70px 0 0 #ffffff,
    500px 70px 0 0 #ffffff, 5px 75px 0 0 #ffffff, 15px 75px 0 0 #ffffff,
    490px 75px 0 0 #ffffff, 500px 75px 0 0 #ffffff, 5px 80px 0 0 #ffffff,
    15px 80px 0 0 #ffffff, 490px 80px 0 0 #ffffff, 500px 80px 0 0 #ffffff,
    5px 85px 0 0 #ffffff, 15px 85px 0 0 #ffffff, 490px 85px 0 0 #ffffff,
    500px 85px 0 0 #ffffff, 5px 90px 0 0 #ffffff, 15px 90px 0 0 #ffffff,
    490px 90px 0 0 #ffffff, 500px 90px 0 0 #ffffff, 5px 95px 0 0 #ffffff,
    15px 95px 0 0 #ffffff, 490px 95px 0 0 #ffffff, 500px 95px 0 0 #ffffff,
    5px 100px 0 0 #ffffff, 15px 100px 0 0 #ffffff, 490px 100px 0 0 #ffffff,
    500px 100px 0 0 #ffffff, 5px 105px 0 0 #ffffff, 15px 105px 0 0 #ffffff,
    490px 105px 0 0 #ffffff, 500px 105px 0 0 #ffffff, 5px 110px 0 0 #ffffff,
    15px 110px 0 0 #ffffff, 490px 110px 0 0 #ffffff, 500px 110px 0 0 #ffffff,
    5px 115px 0 0 #ffffff, 15px 115px 0 0 #ffffff, 490px 115px 0 0 #ffffff,
    500px 115px 0 0 #ffffff, 5px 120px 0 0 #ffffff, 15px 120px 0 0 #ffffff,
    490px 120px 0 0 #ffffff, 500px 120px 0 0 #ffffff, 5px 125px 0 0 #ffffff,
    15px 125px 0 0 #ffffff, 490px 125px 0 0 #ffffff, 500px 125px 0 0 #ffffff,
    5px 130px 0 0 #ffffff, 15px 130px 0 0 #ffffff, 490px 130px 0 0 #ffffff,
    500px 130px 0 0 #ffffff, 5px 135px 0 0 #ffffff, 15px 135px 0 0 #ffffff,
    490px 135px 0 0 #ffffff, 500px 135px 0 0 #ffffff, 5px 140px 0 0 #ffffff,
    15px 140px 0 0 #ffffff, 490px 140px 0 0 #ffffff, 500px 140px 0 0 #ffffff,
    5px 145px 0 0 #ffffff, 15px 145px 0 0 #ffffff, 490px 145px 0 0 #ffffff,
    500px 145px 0 0 #ffffff, 5px 150px 0 0 #ffffff, 15px 150px 0 0 #ffffff,
    490px 150px 0 0 #ffffff, 500px 150px 0 0 #ffffff, 5px 155px 0 0 #ffffff,
    15px 155px 0 0 #ffffff, 490px 155px 0 0 #ffffff, 500px 155px 0 0 #ffffff,
    5px 160px 0 0 #ffffff, 15px 160px 0 0 #ffffff, 490px 160px 0 0 #ffffff,
    500px 160px 0 0 #ffffff, 5px 165px 0 0 #ffffff, 15px 165px 0 0 #ffffff,
    490px 165px 0 0 #ffffff, 500px 165px 0 0 #ffffff, 5px 170px 0 0 #ffffff,
    15px 170px 0 0 #ffffff, 490px 170px 0 0 #ffffff, 500px 170px 0 0 #ffffff,
    5px 175px 0 0 #ffffff, 15px 175px 0 0 #ffffff, 490px 175px 0 0 #ffffff,
    500px 175px 0 0 #ffffff, 5px 180px 0 0 #ffffff, 15px 180px 0 0 #ffffff,
    490px 180px 0 0 #ffffff, 500px 180px 0 0 #ffffff, 5px 185px 0 0 #ffffff,
    15px 185px 0 0 #ffffff, 490px 185px 0 0 #ffffff, 500px 185px 0 0 #ffffff,
    5px 190px 0 0 #ffffff, 15px 190px 0 0 #ffffff, 490px 190px 0 0 #ffffff,
    500px 190px 0 0 #ffffff, 5px 195px 0 0 #ffffff, 15px 195px 0 0 #ffffff,
    490px 195px 0 0 #ffffff, 500px 195px 0 0 #ffffff, 5px 200px 0 0 #ffffff,
    15px 200px 0 0 #ffffff, 490px 200px 0 0 #ffffff, 500px 200px 0 0 #ffffff,
    5px 205px 0 0 #ffffff, 15px 205px 0 0 #ffffff, 490px 205px 0 0 #ffffff,
    500px 205px 0 0 #ffffff, 5px 210px 0 0 #ffffff, 15px 210px 0 0 #ffffff,
    490px 210px 0 0 #ffffff, 500px 210px 0 0 #ffffff, 5px 215px 0 0 #ffffff,
    15px 215px 0 0 #ffffff, 490px 215px 0 0 #ffffff, 500px 215px 0 0 #ffffff,
    5px 220px 0 0 #ffffff, 15px 220px 0 0 #ffffff, 490px 220px 0 0 #ffffff,
    500px 220px 0 0 #ffffff, 5px 225px 0 0 #ffffff, 15px 225px 0 0 #ffffff,
    490px 225px 0 0 #ffffff, 500px 225px 0 0 #ffffff, 5px 230px 0 0 #ffffff,
    15px 230px 0 0 #ffffff, 490px 230px 0 0 #ffffff, 500px 230px 0 0 #ffffff,
    5px 235px 0 0 #ffffff, 15px 235px 0 0 #ffffff, 490px 235px 0 0 #ffffff,
    500px 235px 0 0 #ffffff, 5px 240px 0 0 #ffffff, 15px 240px 0 0 #ffffff,
    490px 240px 0 0 #ffffff, 500px 240px 0 0 #ffffff, 5px 245px 0 0 #ffffff,
    15px 245px 0 0 #ffffff, 490px 245px 0 0 #ffffff, 500px 245px 0 0 #ffffff,
    5px 250px 0 0 #ffffff, 15px 250px 0 0 #ffffff, 490px 250px 0 0 #ffffff,
    500px 250px 0 0 #ffffff, 5px 255px 0 0 #ffffff, 15px 255px 0 0 #ffffff,
    490px 255px 0 0 #ffffff, 500px 255px 0 0 #ffffff, 5px 260px 0 0 #ffffff,
    15px 260px 0 0 #ffffff, 490px 260px 0 0 #ffffff, 500px 260px 0 0 #ffffff,
    5px 265px 0 0 #ffffff, 15px 265px 0 0 #ffffff, 490px 265px 0 0 #ffffff,
    500px 265px 0 0 #ffffff, 5px 270px 0 0 #ffffff, 15px 270px 0 0 #ffffff,
    490px 270px 0 0 #ffffff, 500px 270px 0 0 #ffffff, 5px 275px 0 0 #ffffff,
    15px 275px 0 0 #ffffff, 490px 275px 0 0 #ffffff, 500px 275px 0 0 #ffffff,
    5px 280px 0 0 #ffffff, 15px 280px 0 0 #ffffff, 490px 280px 0 0 #ffffff,
    500px 280px 0 0 #ffffff, 5px 285px 0 0 #ffffff, 15px 285px 0 0 #ffffff,
    490px 285px 0 0 #ffffff, 500px 285px 0 0 #ffffff, 5px 290px 0 0 #ffffff,
    15px 290px 0 0 #ffffff, 490px 290px 0 0 #ffffff, 500px 290px 0 0 #ffffff,
    5px 295px 0 0 #ffffff, 15px 295px 0 0 #ffffff, 490px 295px 0 0 #ffffff,
    500px 295px 0 0 #ffffff, 5px 300px 0 0 #ffffff, 15px 300px 0 0 #ffffff,
    490px 300px 0 0 #ffffff, 500px 300px 0 0 #ffffff, 5px 305px 0 0 #ffffff,
    15px 305px 0 0 #ffffff, 490px 305px 0 0 #ffffff, 500px 305px 0 0 #ffffff,
    5px 310px 0 0 #ffffff, 15px 310px 0 0 #ffffff, 490px 310px 0 0 #ffffff,
    500px 310px 0 0 #ffffff, 5px 315px 0 0 #ffffff, 15px 315px 0 0 #ffffff,
    490px 315px 0 0 #ffffff, 500px 315px 0 0 #ffffff, 5px 320px 0 0 #ffffff,
    15px 320px 0 0 #ffffff, 490px 320px 0 0 #ffffff, 500px 320px 0 0 #ffffff,
    5px 325px 0 0 #ffffff, 15px 325px 0 0 #ffffff, 490px 325px 0 0 #ffffff,
    500px 325px 0 0 #ffffff, 5px 330px 0 0 #ffffff, 15px 330px 0 0 #ffffff,
    490px 330px 0 0 #ffffff, 500px 330px 0 0 #ffffff, 5px 335px 0 0 #ffffff,
    15px 335px 0 0 #ffffff, 490px 335px 0 0 #ffffff, 500px 335px 0 0 #ffffff,
    5px 340px 0 0 #ffffff, 15px 340px 0 0 #ffffff, 490px 340px 0 0 #ffffff,
    500px 340px 0 0 #ffffff, 5px 345px 0 0 #ffffff, 15px 345px 0 0 #ffffff,
    490px 345px 0 0 #ffffff, 500px 345px 0 0 #ffffff, 5px 350px 0 0 #ffffff,
    15px 350px 0 0 #ffffff, 490px 350px 0 0 #ffffff, 500px 350px 0 0 #ffffff,
    5px 355px 0 0 #ffffff, 15px 355px 0 0 #ffffff, 490px 355px 0 0 #ffffff,
    500px 355px 0 0 #ffffff, 5px 360px 0 0 #ffffff, 15px 360px 0 0 #ffffff,
    490px 360px 0 0 #ffffff, 500px 360px 0 0 #ffffff, 5px 365px 0 0 #ffffff,
    15px 365px 0 0 #ffffff, 490px 365px 0 0 #ffffff, 500px 365px 0 0 #ffffff,
    5px 370px 0 0 #ffffff, 15px 370px 0 0 #ffffff, 490px 370px 0 0 #ffffff,
    500px 370px 0 0 #ffffff, 5px 375px 0 0 #ffffff, 15px 375px 0 0 #ffffff,
    490px 375px 0 0 #ffffff, 500px 375px 0 0 #ffffff, 5px 380px 0 0 #ffffff,
    15px 380px 0 0 #ffffff, 490px 380px 0 0 #ffffff, 500px 380px 0 0 #ffffff,
    5px 385px 0 0 #ffffff, 15px 385px 0 0 #ffffff, 490px 385px 0 0 #ffffff,
    500px 385px 0 0 #ffffff, 5px 390px 0 0 #ffffff, 15px 390px 0 0 #ffffff,
    490px 390px 0 0 #ffffff, 500px 390px 0 0 #ffffff, 5px 395px 0 0 #ffffff,
    15px 395px 0 0 #ffffff, 490px 395px 0 0 #ffffff, 500px 395px 0 0 #ffffff,
    5px 400px 0 0 #ffffff, 15px 400px 0 0 #ffffff, 490px 400px 0 0 #ffffff,
    500px 400px 0 0 #ffffff, 5px 405px 0 0 #ffffff, 15px 405px 0 0 #ffffff,
    490px 405px 0 0 #ffffff, 500px 405px 0 0 #ffffff, 5px 410px 0 0 #ffffff,
    15px 410px 0 0 #ffffff, 490px 410px 0 0 #ffffff, 500px 410px 0 0 #ffffff,
    5px 415px 0 0 #ffffff, 15px 415px 0 0 #ffffff, 490px 415px 0 0 #ffffff,
    500px 415px 0 0 #ffffff, 5px 420px 0 0 #ffffff, 15px 420px 0 0 #ffffff,
    490px 420px 0 0 #ffffff, 500px 420px 0 0 #ffffff, 5px 425px 0 0 #ffffff,
    15px 425px 0 0 #ffffff, 490px 425px 0 0 #ffffff, 500px 425px 0 0 #ffffff,
    5px 430px 0 0 #ffffff, 15px 430px 0 0 #ffffff, 490px 430px 0 0 #ffffff,
    500px 430px 0 0 #ffffff, 5px 435px 0 0 #ffffff, 20px 435px 0 0 #ffffff,
    25px 435px 0 0 #ffffff, 30px 435px 0 0 #ffffff, 35px 435px 0 0 #ffffff,
    40px 435px 0 0 #ffffff, 45px 435px 0 0 #ffffff, 50px 435px 0 0 #ffffff,
    55px 435px 0 0 #ffffff, 60px 435px 0 0 #ffffff, 65px 435px 0 0 #ffffff,
    70px 435px 0 0 #ffffff, 75px 435px 0 0 #ffffff, 80px 435px 0 0 #ffffff,
    85px 435px 0 0 #ffffff, 90px 435px 0 0 #ffffff, 95px 435px 0 0 #ffffff,
    100px 435px 0 0 #ffffff, 105px 435px 0 0 #ffffff, 110px 435px 0 0 #ffffff,
    115px 435px 0 0 #ffffff, 120px 435px 0 0 #ffffff, 125px 435px 0 0 #ffffff,
    130px 435px 0 0 #ffffff, 135px 435px 0 0 #ffffff, 140px 435px 0 0 #ffffff,
    145px 435px 0 0 #ffffff, 150px 435px 0 0 #ffffff, 155px 435px 0 0 #ffffff,
    160px 435px 0 0 #ffffff, 165px 435px 0 0 #ffffff, 170px 435px 0 0 #ffffff,
    175px 435px 0 0 #ffffff, 180px 435px 0 0 #ffffff, 185px 435px 0 0 #ffffff,
    190px 435px 0 0 #ffffff, 195px 435px 0 0 #ffffff, 200px 435px 0 0 #ffffff,
    205px 435px 0 0 #ffffff, 210px 435px 0 0 #ffffff, 215px 435px 0 0 #ffffff,
    220px 435px 0 0 #ffffff, 225px 435px 0 0 #ffffff, 230px 435px 0 0 #ffffff,
    235px 435px 0 0 #ffffff, 240px 435px 0 0 #ffffff, 245px 435px 0 0 #ffffff,
    250px 435px 0 0 #ffffff, 255px 435px 0 0 #ffffff, 260px 435px 0 0 #ffffff,
    265px 435px 0 0 #ffffff, 270px 435px 0 0 #ffffff, 275px 435px 0 0 #ffffff,
    280px 435px 0 0 #ffffff, 285px 435px 0 0 #ffffff, 290px 435px 0 0 #ffffff,
    295px 435px 0 0 #ffffff, 300px 435px 0 0 #ffffff, 305px 435px 0 0 #ffffff,
    310px 435px 0 0 #ffffff, 315px 435px 0 0 #ffffff, 320px 435px 0 0 #ffffff,
    325px 435px 0 0 #ffffff, 330px 435px 0 0 #ffffff, 335px 435px 0 0 #ffffff,
    340px 435px 0 0 #ffffff, 345px 435px 0 0 #ffffff, 350px 435px 0 0 #ffffff,
    355px 435px 0 0 #ffffff, 360px 435px 0 0 #ffffff, 365px 435px 0 0 #ffffff,
    370px 435px 0 0 #ffffff, 375px 435px 0 0 #ffffff, 380px 435px 0 0 #ffffff,
    385px 435px 0 0 #ffffff, 390px 435px 0 0 #ffffff, 395px 435px 0 0 #ffffff,
    400px 435px 0 0 #ffffff, 405px 435px 0 0 #ffffff, 410px 435px 0 0 #ffffff,
    415px 435px 0 0 #ffffff, 420px 435px 0 0 #ffffff, 425px 435px 0 0 #ffffff,
    430px 435px 0 0 #ffffff, 435px 435px 0 0 #ffffff, 440px 435px 0 0 #ffffff,
    445px 435px 0 0 #ffffff, 450px 435px 0 0 #ffffff, 455px 435px 0 0 #ffffff,
    460px 435px 0 0 #ffffff, 465px 435px 0 0 #ffffff, 470px 435px 0 0 #ffffff,
    475px 435px 0 0 #ffffff, 480px 435px 0 0 #ffffff, 485px 435px 0 0 #ffffff,
    500px 435px 0 0 #ffffff, 10px 440px 0 0 #ffffff, 495px 440px 0 0 #ffffff,
    15px 445px 0 0 #ffffff, 20px 445px 0 0 #ffffff, 25px 445px 0 0 #ffffff,
    30px 445px 0 0 #ffffff, 35px 445px 0 0 #ffffff, 40px 445px 0 0 #ffffff,
    45px 445px 0 0 #ffffff, 50px 445px 0 0 #ffffff, 55px 445px 0 0 #ffffff,
    60px 445px 0 0 #ffffff, 65px 445px 0 0 #ffffff, 70px 445px 0 0 #ffffff,
    75px 445px 0 0 #ffffff, 80px 445px 0 0 #ffffff, 85px 445px 0 0 #ffffff,
    90px 445px 0 0 #ffffff, 95px 445px 0 0 #ffffff, 100px 445px 0 0 #ffffff,
    105px 445px 0 0 #ffffff, 110px 445px 0 0 #ffffff, 115px 445px 0 0 #ffffff,
    120px 445px 0 0 #ffffff, 125px 445px 0 0 #ffffff, 130px 445px 0 0 #ffffff,
    135px 445px 0 0 #ffffff, 140px 445px 0 0 #ffffff, 145px 445px 0 0 #ffffff,
    150px 445px 0 0 #ffffff, 155px 445px 0 0 #ffffff, 160px 445px 0 0 #ffffff,
    165px 445px 0 0 #ffffff, 170px 445px 0 0 #ffffff, 175px 445px 0 0 #ffffff,
    180px 445px 0 0 #ffffff, 185px 445px 0 0 #ffffff, 190px 445px 0 0 #ffffff,
    195px 445px 0 0 #ffffff, 200px 445px 0 0 #ffffff, 205px 445px 0 0 #ffffff,
    210px 445px 0 0 #ffffff, 215px 445px 0 0 #ffffff, 220px 445px 0 0 #ffffff,
    225px 445px 0 0 #ffffff, 230px 445px 0 0 #ffffff, 235px 445px 0 0 #ffffff,
    240px 445px 0 0 #ffffff, 245px 445px 0 0 #ffffff, 250px 445px 0 0 #ffffff,
    255px 445px 0 0 #ffffff, 260px 445px 0 0 #ffffff, 265px 445px 0 0 #ffffff,
    270px 445px 0 0 #ffffff, 275px 445px 0 0 #ffffff, 280px 445px 0 0 #ffffff,
    285px 445px 0 0 #ffffff, 290px 445px 0 0 #ffffff, 295px 445px 0 0 #ffffff,
    300px 445px 0 0 #ffffff, 305px 445px 0 0 #ffffff, 310px 445px 0 0 #ffffff,
    315px 445px 0 0 #ffffff, 320px 445px 0 0 #ffffff, 325px 445px 0 0 #ffffff,
    330px 445px 0 0 #ffffff, 335px 445px 0 0 #ffffff, 340px 445px 0 0 #ffffff,
    345px 445px 0 0 #ffffff, 350px 445px 0 0 #ffffff, 355px 445px 0 0 #ffffff,
    360px 445px 0 0 #ffffff, 365px 445px 0 0 #ffffff, 370px 445px 0 0 #ffffff,
    375px 445px 0 0 #ffffff, 380px 445px 0 0 #ffffff, 385px 445px 0 0 #ffffff,
    390px 445px 0 0 #ffffff, 395px 445px 0 0 #ffffff, 400px 445px 0 0 #ffffff,
    405px 445px 0 0 #ffffff, 410px 445px 0 0 #ffffff, 415px 445px 0 0 #ffffff,
    420px 445px 0 0 #ffffff, 425px 445px 0 0 #ffffff, 430px 445px 0 0 #ffffff,
    435px 445px 0 0 #ffffff, 440px 445px 0 0 #ffffff, 445px 445px 0 0 #ffffff,
    450px 445px 0 0 #ffffff, 455px 445px 0 0 #ffffff, 460px 445px 0 0 #ffffff,
    465px 445px 0 0 #ffffff, 470px 445px 0 0 #ffffff, 475px 445px 0 0 #ffffff,
    480px 445px 0 0 #ffffff, 485px 445px 0 0 #ffffff, 490px 445px 0 0 #ffffff;
  height: 5px;
  width: 5px;
}
.first__skills {
  width: 100%;
  row-gap: 10%;
  margin-left: 20%;
  min-height: 40%;
}
.first__skills h1 {
  margin-bottom: 2%;
}
.studies {
  min-height: 20% !important;
  margin-left: 0 !important;
  column-gap: 5%;
}
.stats__languaje {
  width: 100%;
  padding-top: 1%;
  padding-left: 5%;
  top: 10%;
  margin-bottom: 2%;
  left: 100px;
}
.languaje__title {
  column-gap: 10%;
  margin-bottom: 5%;
}
.languaje__frame {
  max-height: 70px;
}
.languaje__title h3 {
  margin: 0;
}
.languaje__title img {
  width: 25px;
  height: 25px;
}
.chargin__skill {
  animation: x 5s forwards;
  -webkit-animation: x 5s forwards;
  -moz-animation: x 5s forwards;
  -o-animation: x 5s forwards;
}
.services__img {
  width: 100px !important;
  height: 100px !important;
}
@keyframes x {
  0%,
  50% {
    box-shadow: 15px 10px 0 0 #ffffff, 20px 10px 0 0 #ffffff,
      25px 10px 0 0 #ffffff, 30px 10px 0 0 #ffffff, 35px 10px 0 0 #ffffff,
      40px 10px 0 0 #ffffff, 45px 10px 0 0 #ffffff, 50px 10px 0 0 #ffffff,
      55px 10px 0 0 #ffffff, 60px 10px 0 0 #ffffff, 65px 10px 0 0 #ffffff,
      70px 10px 0 0 #ffffff, 75px 10px 0 0 #ffffff, 80px 10px 0 0 #ffffff,
      85px 10px 0 0 #ffffff, 90px 10px 0 0 #ffffff, 95px 10px 0 0 #ffffff,
      100px 10px 0 0 #ffffff, 105px 10px 0 0 #ffffff, 110px 10px 0 0 #ffffff,
      115px 10px 0 0 #ffffff, 120px 10px 0 0 #ffffff, 125px 10px 0 0 #ffffff,
      130px 10px 0 0 #ffffff, 135px 10px 0 0 #ffffff, 140px 10px 0 0 #ffffff,
      145px 10px 0 0 #ffffff, 150px 10px 0 0 #ffffff, 155px 10px 0 0 #ffffff,
      160px 10px 0 0 #ffffff, 165px 10px 0 0 #ffffff, 170px 10px 0 0 #ffffff,
      175px 10px 0 0 #ffffff, 180px 10px 0 0 #ffffff, 185px 10px 0 0 #ffffff,
      190px 10px 0 0 #ffffff, 195px 10px 0 0 #ffffff, 200px 10px 0 0 #ffffff,
      205px 10px 0 0 #ffffff, 210px 10px 0 0 #ffffff, 215px 10px 0 0 #ffffff,
      220px 10px 0 0 #ffffff, 225px 10px 0 0 #ffffff, 230px 10px 0 0 #ffffff,
      235px 10px 0 0 #ffffff, 240px 10px 0 0 #ffffff, 245px 10px 0 0 #ffffff,
      250px 10px 0 0 #ffffff, 255px 10px 0 0 #ffffff, 260px 10px 0 0 #ffffff,
      265px 10px 0 0 #ffffff, 270px 10px 0 0 #ffffff, 10px 15px 0 0 #ffffff,
      15px 15px 0 0 #ffffff, 20px 15px 0 0 #4caf50, 25px 15px 0 0 #4caf50,
      30px 15px 0 0 #4caf50, 35px 15px 0 0 #4caf50, 40px 15px 0 0 #4caf50,
      45px 15px 0 0 #4caf50, 50px 15px 0 0 #4caf50, 55px 15px 0 0 #4caf50,
      60px 15px 0 0 #4caf50, 65px 15px 0 0 #4caf50, 70px 15px 0 0 #4caf50,
      75px 15px 0 0 #4caf50, 80px 15px 0 0 #4caf50, 85px 15px 0 0 #4caf50,
      90px 15px 0 0 #4caf50, 95px 15px 0 0 #4caf50, 100px 15px 0 0 #4caf50,
      105px 15px 0 0 #4caf50, 110px 15px 0 0 #4caf50, 115px 15px 0 0 #4caf50,
      120px 15px 0 0 #4caf50, 125px 15px 0 0 #4caf50, 130px 15px 0 0 #4caf50,
      135px 15px 0 0 #4caf50, 140px 15px 0 0 #4caf50, 145px 15px 0 0 #4caf50,
      150px 15px 0 0 #4caf50, 155px 15px 0 0 #4caf50, 160px 15px 0 0 #4caf50,
      165px 15px 0 0 #4caf50, 170px 15px 0 0 #4caf50, 175px 15px 0 0 #4caf50,
      180px 15px 0 0 #4caf50, 185px 15px 0 0 #4caf50, 190px 15px 0 0 #4caf50,
      195px 15px 0 0 #4caf50, 200px 15px 0 0 #4caf50, 205px 15px 0 0 #4caf50,
      210px 15px 0 0 #4caf50, 215px 15px 0 0 #4caf50, 220px 15px 0 0 #4caf50,
      225px 15px 0 0 #4caf50, 230px 15px 0 0 #4caf50, 235px 15px 0 0 #4caf50,
      240px 15px 0 0 #4caf50, 245px 15px 0 0 #4caf50, 250px 15px 0 0 #000000,
      255px 15px 0 0 #000000, 260px 15px 0 0 #000000, 265px 15px 0 0 #000000,
      270px 15px 0 0 #ffffff, 275px 15px 0 0 #ffffff, 10px 20px 0 0 #ffffff,
      15px 20px 0 0 #ffffff, 20px 20px 0 0 #4caf50, 25px 20px 0 0 #4caf50,
      30px 20px 0 0 #4caf50, 35px 20px 0 0 #4caf50, 40px 20px 0 0 #4caf50,
      45px 20px 0 0 #4caf50, 50px 20px 0 0 #4caf50, 55px 20px 0 0 #4caf50,
      60px 20px 0 0 #4caf50, 65px 20px 0 0 #4caf50, 70px 20px 0 0 #4caf50,
      75px 20px 0 0 #4caf50, 80px 20px 0 0 #4caf50, 85px 20px 0 0 #4caf50,
      90px 20px 0 0 #4caf50, 95px 20px 0 0 #4caf50, 100px 20px 0 0 #4caf50,
      105px 20px 0 0 #4caf50, 110px 20px 0 0 #4caf50, 115px 20px 0 0 #4caf50,
      120px 20px 0 0 #4caf50, 125px 20px 0 0 #4caf50, 130px 20px 0 0 #4caf50,
      135px 20px 0 0 #4caf50, 140px 20px 0 0 #4caf50, 145px 20px 0 0 #4caf50,
      150px 20px 0 0 #4caf50, 155px 20px 0 0 #4caf50, 160px 20px 0 0 #4caf50,
      165px 20px 0 0 #4caf50, 170px 20px 0 0 #4caf50, 175px 20px 0 0 #4caf50,
      180px 20px 0 0 #4caf50, 185px 20px 0 0 #4caf50, 190px 20px 0 0 #4caf50,
      195px 20px 0 0 #4caf50, 200px 20px 0 0 #4caf50, 205px 20px 0 0 #4caf50,
      210px 20px 0 0 #4caf50, 215px 20px 0 0 #4caf50, 220px 20px 0 0 #4caf50,
      225px 20px 0 0 #4caf50, 230px 20px 0 0 #4caf50, 235px 20px 0 0 #4caf50,
      240px 20px 0 0 #4caf50, 245px 20px 0 0 #4caf50, 250px 20px 0 0 #4caf50,
      255px 20px 0 0 #000000, 260px 20px 0 0 #000000, 265px 20px 0 0 #000000,
      270px 20px 0 0 #ffffff, 275px 20px 0 0 #ffffff, 15px 25px 0 0 #ffffff,
      20px 25px 0 0 #ffffff, 25px 25px 0 0 #ffffff, 30px 25px 0 0 #ffffff,
      35px 25px 0 0 #ffffff, 40px 25px 0 0 #ffffff, 45px 25px 0 0 #ffffff,
      50px 25px 0 0 #ffffff, 55px 25px 0 0 #ffffff, 60px 25px 0 0 #ffffff,
      65px 25px 0 0 #ffffff, 70px 25px 0 0 #ffffff, 75px 25px 0 0 #ffffff,
      80px 25px 0 0 #ffffff, 85px 25px 0 0 #ffffff, 90px 25px 0 0 #ffffff,
      95px 25px 0 0 #ffffff, 100px 25px 0 0 #ffffff, 105px 25px 0 0 #ffffff,
      110px 25px 0 0 #ffffff, 115px 25px 0 0 #ffffff, 120px 25px 0 0 #ffffff,
      125px 25px 0 0 #ffffff, 130px 25px 0 0 #ffffff, 135px 25px 0 0 #ffffff,
      140px 25px 0 0 #ffffff, 145px 25px 0 0 #ffffff, 150px 25px 0 0 #ffffff,
      155px 25px 0 0 #ffffff, 160px 25px 0 0 #ffffff, 165px 25px 0 0 #ffffff,
      170px 25px 0 0 #ffffff, 175px 25px 0 0 #ffffff, 180px 25px 0 0 #ffffff,
      185px 25px 0 0 #ffffff, 190px 25px 0 0 #ffffff, 195px 25px 0 0 #ffffff,
      200px 25px 0 0 #ffffff, 205px 25px 0 0 #ffffff, 210px 25px 0 0 #ffffff,
      215px 25px 0 0 #ffffff, 220px 25px 0 0 #ffffff, 225px 25px 0 0 #ffffff,
      230px 25px 0 0 #ffffff, 235px 25px 0 0 #ffffff, 240px 25px 0 0 #ffffff,
      245px 25px 0 0 #ffffff, 250px 25px 0 0 #ffffff, 255px 25px 0 0 #ffffff,
      260px 25px 0 0 #ffffff, 265px 25px 0 0 #ffffff, 270px 25px 0 0 #ffffff;
    height: 5px;
    width: 5px;
  }
  50.01%,
  100% {
    box-shadow: 15px 10px 0 0 #ffffff, 20px 10px 0 0 #ffffff,
      25px 10px 0 0 #ffffff, 30px 10px 0 0 #ffffff, 35px 10px 0 0 #ffffff,
      40px 10px 0 0 #ffffff, 45px 10px 0 0 #ffffff, 50px 10px 0 0 #ffffff,
      55px 10px 0 0 #ffffff, 60px 10px 0 0 #ffffff, 65px 10px 0 0 #ffffff,
      70px 10px 0 0 #ffffff, 75px 10px 0 0 #ffffff, 80px 10px 0 0 #ffffff,
      85px 10px 0 0 #ffffff, 90px 10px 0 0 #ffffff, 95px 10px 0 0 #ffffff,
      100px 10px 0 0 #ffffff, 105px 10px 0 0 #ffffff, 110px 10px 0 0 #ffffff,
      115px 10px 0 0 #ffffff, 120px 10px 0 0 #ffffff, 125px 10px 0 0 #ffffff,
      130px 10px 0 0 #ffffff, 135px 10px 0 0 #ffffff, 140px 10px 0 0 #ffffff,
      145px 10px 0 0 #ffffff, 150px 10px 0 0 #ffffff, 155px 10px 0 0 #ffffff,
      160px 10px 0 0 #ffffff, 165px 10px 0 0 #ffffff, 170px 10px 0 0 #ffffff,
      175px 10px 0 0 #ffffff, 180px 10px 0 0 #ffffff, 185px 10px 0 0 #ffffff,
      190px 10px 0 0 #ffffff, 195px 10px 0 0 #ffffff, 200px 10px 0 0 #ffffff,
      205px 10px 0 0 #ffffff, 210px 10px 0 0 #ffffff, 215px 10px 0 0 #ffffff,
      220px 10px 0 0 #ffffff, 225px 10px 0 0 #ffffff, 230px 10px 0 0 #ffffff,
      235px 10px 0 0 #ffffff, 240px 10px 0 0 #ffffff, 245px 10px 0 0 #ffffff,
      250px 10px 0 0 #ffffff, 255px 10px 0 0 #ffffff, 260px 10px 0 0 #ffffff,
      265px 10px 0 0 #ffffff, 270px 10px 0 0 #ffffff, 10px 15px 0 0 #ffffff,
      15px 15px 0 0 #ffffff, 20px 15px 0 0 #4caf50, 25px 15px 0 0 #4caf50,
      30px 15px 0 0 #4caf50, 35px 15px 0 0 #4caf50, 40px 15px 0 0 #4caf50,
      45px 15px 0 0 #4caf50, 50px 15px 0 0 #4caf50, 55px 15px 0 0 #4caf50,
      60px 15px 0 0 #4caf50, 65px 15px 0 0 #4caf50, 70px 15px 0 0 #4caf50,
      75px 15px 0 0 #4caf50, 80px 15px 0 0 #4caf50, 85px 15px 0 0 #4caf50,
      90px 15px 0 0 #4caf50, 95px 15px 0 0 #4caf50, 100px 15px 0 0 #4caf50,
      105px 15px 0 0 #4caf50, 110px 15px 0 0 #4caf50, 115px 15px 0 0 #4caf50,
      120px 15px 0 0 #4caf50, 125px 15px 0 0 #4caf50, 130px 15px 0 0 #4caf50,
      135px 15px 0 0 #4caf50, 140px 15px 0 0 #4caf50, 145px 15px 0 0 #4caf50,
      150px 15px 0 0 #4caf50, 155px 15px 0 0 #4caf50, 160px 15px 0 0 #4caf50,
      165px 15px 0 0 #4caf50, 170px 15px 0 0 #4caf50, 175px 15px 0 0 #4caf50,
      180px 15px 0 0 #4caf50, 185px 15px 0 0 #4caf50, 190px 15px 0 0 #4caf50,
      195px 15px 0 0 #4caf50, 200px 15px 0 0 #4caf50, 205px 15px 0 0 #4caf50,
      210px 15px 0 0 #4caf50, 215px 15px 0 0 #4caf50, 220px 15px 0 0 #4caf50,
      225px 15px 0 0 #000000, 230px 15px 0 0 #000000, 235px 15px 0 0 #000000,
      240px 15px 0 0 #000000, 245px 15px 0 0 #000000, 250px 15px 0 0 #000000,
      255px 15px 0 0 #000000, 260px 15px 0 0 #000000, 265px 15px 0 0 #000000,
      270px 15px 0 0 #ffffff, 275px 15px 0 0 #ffffff, 10px 20px 0 0 #ffffff,
      15px 20px 0 0 #ffffff, 20px 20px 0 0 #4caf50, 25px 20px 0 0 #4caf50,
      30px 20px 0 0 #4caf50, 35px 20px 0 0 #4caf50, 40px 20px 0 0 #4caf50,
      45px 20px 0 0 #4caf50, 50px 20px 0 0 #4caf50, 55px 20px 0 0 #4caf50,
      60px 20px 0 0 #4caf50, 65px 20px 0 0 #4caf50, 70px 20px 0 0 #4caf50,
      75px 20px 0 0 #4caf50, 80px 20px 0 0 #4caf50, 85px 20px 0 0 #4caf50,
      90px 20px 0 0 #4caf50, 95px 20px 0 0 #4caf50, 100px 20px 0 0 #4caf50,
      105px 20px 0 0 #4caf50, 110px 20px 0 0 #4caf50, 115px 20px 0 0 #4caf50,
      120px 20px 0 0 #4caf50, 125px 20px 0 0 #4caf50, 130px 20px 0 0 #4caf50,
      135px 20px 0 0 #4caf50, 140px 20px 0 0 #4caf50, 145px 20px 0 0 #4caf50,
      150px 20px 0 0 #4caf50, 155px 20px 0 0 #4caf50, 160px 20px 0 0 #4caf50,
      165px 20px 0 0 #4caf50, 170px 20px 0 0 #4caf50, 175px 20px 0 0 #4caf50,
      180px 20px 0 0 #4caf50, 185px 20px 0 0 #4caf50, 190px 20px 0 0 #4caf50,
      195px 20px 0 0 #4caf50, 200px 20px 0 0 #4caf50, 205px 20px 0 0 #4caf50,
      210px 20px 0 0 #4caf50, 215px 20px 0 0 #4caf50, 220px 20px 0 0 #4caf50,
      225px 20px 0 0 #4caf50, 230px 20px 0 0 #000000, 235px 20px 0 0 #000000,
      240px 20px 0 0 #000000, 245px 20px 0 0 #000000, 250px 20px 0 0 #000000,
      255px 20px 0 0 #000000, 260px 20px 0 0 #000000, 265px 20px 0 0 #000000,
      270px 20px 0 0 #ffffff, 275px 20px 0 0 #ffffff, 15px 25px 0 0 #ffffff,
      20px 25px 0 0 #ffffff, 25px 25px 0 0 #ffffff, 30px 25px 0 0 #ffffff,
      35px 25px 0 0 #ffffff, 40px 25px 0 0 #ffffff, 45px 25px 0 0 #ffffff,
      50px 25px 0 0 #ffffff, 55px 25px 0 0 #ffffff, 60px 25px 0 0 #ffffff,
      65px 25px 0 0 #ffffff, 70px 25px 0 0 #ffffff, 75px 25px 0 0 #ffffff,
      80px 25px 0 0 #ffffff, 85px 25px 0 0 #ffffff, 90px 25px 0 0 #ffffff,
      95px 25px 0 0 #ffffff, 100px 25px 0 0 #ffffff, 105px 25px 0 0 #ffffff,
      110px 25px 0 0 #ffffff, 115px 25px 0 0 #ffffff, 120px 25px 0 0 #ffffff,
      125px 25px 0 0 #ffffff, 130px 25px 0 0 #ffffff, 135px 25px 0 0 #ffffff,
      140px 25px 0 0 #ffffff, 145px 25px 0 0 #ffffff, 150px 25px 0 0 #ffffff,
      155px 25px 0 0 #ffffff, 160px 25px 0 0 #ffffff, 165px 25px 0 0 #ffffff,
      170px 25px 0 0 #ffffff, 175px 25px 0 0 #ffffff, 180px 25px 0 0 #ffffff,
      185px 25px 0 0 #ffffff, 190px 25px 0 0 #ffffff, 195px 25px 0 0 #ffffff,
      200px 25px 0 0 #ffffff, 205px 25px 0 0 #ffffff, 210px 25px 0 0 #ffffff,
      215px 25px 0 0 #ffffff, 220px 25px 0 0 #ffffff, 225px 25px 0 0 #ffffff,
      230px 25px 0 0 #ffffff, 235px 25px 0 0 #ffffff, 240px 25px 0 0 #ffffff,
      245px 25px 0 0 #ffffff, 250px 25px 0 0 #ffffff, 255px 25px 0 0 #ffffff,
      260px 25px 0 0 #ffffff, 265px 25px 0 0 #ffffff, 270px 25px 0 0 #ffffff;
    height: 5px;
    width: 5px;
  }
}
@media (max-width: 1000px) {
  .Estudios {
    display: flex;
    flex-direction: column;
  }
  .academics__save__img {
    display: none;
  }
  .estudios__first {
    align-items: center;
    justify-content: center !important;
  }

  .first__skills {
    margin-left: 0;
    align-items: center;
    justify-content: center;
  }
  .stats__languaje {
    align-items: center;
    padding-left: 0;
    justify-content: center;
  }
  .chargin__skill {
    margin-right: 25%;
  }
}
@media (max-width: 1400px) {
  .educacion {
    display: none !important;
  }
  .Estudios {
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 500px) {
  .chargin__skill {
    display: none;
  }
  .Estudios {
    padding-left: 0;
  }
  .first__skills {
    row-gap: 10%;
    margin-bottom: 5%;
  }
  .first__skills h1 {
    margin-bottom: 10%;
  }
  .estudios__titulo {
    display: block !important;
  }
}
.estudios__titulo {
  display: none;
}
