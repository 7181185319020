/* Fondo con efecto de blur */
.form-container_ {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: url("https://source.unsplash.com/1600x900/?nature") no-repeat
    center center/cover;
  position: fixed; /* Fijo en la pantalla */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.contenedor-boton-iniciar {
  max-width: 150px;
  cursor: pointer;
}
.text-iniciar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #02352d;
  font-size: 12px;
  text-transform: uppercase;
}
.boton-iniciar {
  width: 100%;
}
.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}
/* Tarjeta del formulario */
/* Agregar una transición de fade-in y deslizamiento */
.form-card {
  opacity: 0;
  transform: translateY(20px); /* Desliza desde abajo */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

/* Opcional: Agregar un efecto de desvanecimiento al hacer clic en el botón */
.boton-iniciar {
  cursor: pointer;
}

.position__relative {
  position: relative;
  text-align: center;
}

.text-iniciar {
  font-size: 18px;
  font-weight: bold;
}

.form-container_ {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-blur_ {
  position: relative;
  width: 80%;
  max-width: 600px;
  background: rgba(56, 69, 75, 0.8); /* Color #38454b con opacidad */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.form-card.transparent {
  background: transparent;
}
.recaptcha {
  max-width: 50px;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  color: #ff0000; /* Rojo para resaltar */
  height: 100px;
  margin-top: 30px;
}

.countdown-text {
  font-size: 3rem;
  color: #007bff; /* Azul para la cuenta regresiva */
}
