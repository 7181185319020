@import url("https://fonts.googleapis.com/css2?family=Allura&family=Gelasio:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.Menu {
  display: grid;
  grid-template-columns: 70px 1fr;
  height: 100vh;
  opacity: 1;
}
.Menu_mini {
  padding: 10px 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.5s ease-in-out;
}
.sidebar__menu-line {
  overflow: hidden;
  position: relative;
  width: 40%;
  height: 40px;
  cursor: pointer;
  align-self: center;
  background-color: transparent;
  border: none;
  padding: 0;
}

.sidebar__menu-line:after,
.sidebar__menu-line:before {
  position: absolute;
  display: block;
  width: 100%;
  height: 1.5px;
  content: "";
  background: #000;
  transition: all 0.7s ease-out;
}
.sidebar__menu-line:before {
  top: 6px;
}

.sidebar__menu-line.crossed:before {
  transform: rotate(45deg);
}
.sidebar__menu-line.crossed:after {
  transform: rotate(-45deg);
}

.sidebar__menu-line.crossed:after {
  top: 7px;
}

.Menu__access {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  height: 10%;
  background: transparent;
  cursor: pointer;
}
.Menu_socialmedia {
  display: flex;
  height: 120px;
  flex-direction: column;
  row-gap: 10px;
  justify-content: space-between;
}
.Menu_socialmedia i {
  margin: 5px;
  font-size: 16px;
  color: #000;
}
.Menu_me {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  border-radius: 100px;
  height: 50px;
  overflow: hidden;
}
.Menu_me img {
  width: 100%;
  height: 100%;
}
.bi-list {
  position: absolute;
  top: 5%;
  cursor: pointer;
  left: 50%;
  font-size: 24px;
  transform: translate(-50%, -50%);
  opacity: 1 !important; /* No será afectado por el opacity: 0 del padre */
  z-index: 10; /* Asegura que esté por encima de otros elementos */
}
.Menu_max {
  width: 100vw;
  height: 100%;
  background-color: #000;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 70px;
  opacity: 0;
  overflow: hidden;
  color: #fff;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(-100%);
}
.Menu_max > * {
  padding-left: 10%;
  padding-top: 4%;
}
.menu-sign {
  font-size: 24px;
  font-family: "Allura", cursive;
}
.menu-subtitle {
  letter-spacing: 1px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.Menu_max ul {
  margin: 0;
  padding: 0;
}
.Menu_max ul li {
  display: flex;
  flex-direction: row;
  font-family: "Gelasio", serif;
  font-size: 50px;
  column-gap: 4%;
  margin: 2%;
}
.Menu_max ul li img {
  width: 100%;
  margin: 0 auto;
  align-self: center;
  height: 100px;
}
.Menu_max ul li p {
  margin: 0;
  cursor: pointer;
  align-self: center;
}
.Menu_max ul li p:hover {
  color: #aaa;
}
.Menu_max.show {
  opacity: 1;
  transform: translateY(0);
}
@media screen and (max-width: 500px) {
  .Menu_max ul li {
    font-size: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .Menu_max ul li p {
    grid-column: span 2;
  }
  .Menu_max ul li img {
    width: 100%;
    margin: 0;
  }
  .Menu_mini {
    background-color: transparent;
  }
  .Menu_mini div {
    display: none;
  }
  .Menu_options {
    padding-left: 0;
    padding-top: 25%;
  }
  .Menu_options ul li {
    display: flex;
  }
}
.Menu_me {
  cursor: pointer;
  transition: transform 0.3s ease, width 0.5s ease, height 0.5s ease,
    border-radius 0.5s ease;

  border-radius: 50%; /* Borde inicialmente redondeado */
  overflow: hidden; /* Para ocultar los bordes cuando cambian */
}

.Menu_me.expanded {
  transform: translateX(calc(50vw - 35px)); /* Centra horizontalmente */
  width: 300px; /* Tamaño expandido */
  height: 350px; /* Tamaño expandido */
  border-radius: 0; /* Borde cuadrado al expandirse */
  background-color: white; /* Cambia el color de fondo al expandirse */
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 20%;
  padding-top: 20%;
  display: flex;
  flex-direction: column;
}
/* .Menu_me.expanded img { 
  width: 300px;
  height: 400px;
  border-radius: 0px;
} */
.Menu_max img {
  max-width: 500px;
}
li {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.bi-caret-right-fill {
  transition: transform 0.2s ease-in-out;
}

li:hover .bi-caret-right-fill {
  animation: apuntando 0.2s infinite alternate ease-in-out;
}

@keyframes apuntando {
  0% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(-2px);
  }
}
.fire-container {
  position: relative;
  display: inline-block;
  align-items: flex-end;
  align-self: flex-end;
  margin-right: 20%;
}

.fire-container img {
  display: block;
  width: 100%;
  height: auto;
}

/* Contenedor de chispas */
.spark-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Para que no interfiera con eventos del usuario */
}
.save-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;
  margin-right: 25%;
}
.floppy {
  width: 20px;
  height: 16px;
}
/* Chispas */
.spark {
  position: absolute;
  bottom: 80%; /* Aparecen por encima del fuego */
  width: 6px;
  height: 6px;
  background-color: #e5233e; /* Naranja fuego */
  box-shadow: 0 0 5px rgba(255, 140, 0, 0.9);
  opacity: 0;
  animation: sparkAnimation 1.5s infinite linear;
}

/* Animación de chispas */
@keyframes sparkAnimation {
  0% {
    opacity: 1;
    transform: translateY(0) translateX(0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-60px) translateX(var(--x-move)) scale(0.5);
  }
}

/* Posicionamiento y delays aleatorios */
.spark:nth-child(1) {
  left: 10%;
  animation-delay: 0s;
  --x-move: -10px;
}
.spark:nth-child(2) {
  left: 30%;
  animation-delay: 0.3s;
  --x-move: 15px;
}
.spark:nth-child(3) {
  left: 50%;
  animation-delay: 0.5s;
  --x-move: -20px;
}
.spark:nth-child(4) {
  left: 70%;
  animation-delay: 0.8s;
  --x-move: 10px;
}
.spark:nth-child(5) {
  left: 90%;
  animation-delay: 1s;
  --x-move: -15px;
}
.spark:nth-child(6) {
  left: 20%;
  animation-delay: 1.2s;
  --x-move: 12px;
}
.spark:nth-child(7) {
  left: 40%;
  animation-delay: 1.5s;
  --x-move: -8px;
}
.spark:nth-child(8) {
  left: 60%;
  animation-delay: 1.7s;
  --x-move: 5px;
}
.Menu_options {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.saving-text {
  font-size: 16px;
  font-weight: bold;
  color: white;
  letter-spacing: 1px;
}

/* Estilos para los puntos */
.saving-dots span {
  opacity: 0.3;
  animation: blink 1.5s infinite;
}

/* Animaciones secuenciales */
.saving-dots span:nth-child(1) {
  animation-delay: 0s;
}
.saving-dots span:nth-child(2) {
  animation-delay: 0.3s;
}
.saving-dots span:nth-child(3) {
  animation-delay: 0.6s;
}
.social-icons {
  width: 25px;
}
@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
